.StudentAsignBox {
    width: 100%;
    /* height: 100%; */

    /* background-color: aqua; */
    padding: 2vmax;
    display: flex;
    justify-content: center;
}

.StudentAsignBox>form {
    width: 50%;
    background-color: white;
    box-shadow: 0 0 10px gray;
    padding: 1vmax;

    border-radius: 0.4rem;
    overflow: hidden;
}

.StudentAsignBox>form>h3 {
    padding: 0.99vmax 1vmax;
    background: linear-gradient(to right, var(--bgBlue) , var(--bgBlueLight)) ;
    color: var(--textC2);
    font: 600 1.1vmax 'Roboto';
    border-radius: 0.4rem;

}

.inputItem {
    padding: 0.5vmax 0.6vmax;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.inputItem>p {
    font: 600 1vmax 'Roboto';
    color: var(--textC1);
    /* padding: ; */
}

.inputItem>input {
    width: 100%;
    padding: 0.4vmax;
    font: 300 1.2vmax 'Roboto';
    border-radius: 0.4rem;
    /* outline: 2px solid var(--bgBlue); */
    outline: none;
    border: 2px solid var(--bg1);
}



.submitItem {
    padding: 1vmax 0.6vmax;
    padding-bottom: 1.5vmax;

}

.history {
    padding: 0 1vmax;
    font-weight: 600;
}

.submitItem>input {
    width: 100%;
    padding: 0.5vmax 3vmax;
    font: 600 1vmax 'Roboto';
    border-radius: 0.4rem;
    /* outline: 2px solid var(--bgBlue); */
    outline: none;
    /* border: 2px solid var(--bgBlue); */
    background-color: var(--bgBlue);
    color: white;
    cursor: pointer;
}

.submitItem>input:hover {
    background-color: var(--bgHov);
}

.asignHisContainer {
    margin: 0.5vmax 0.6vmax;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    /* border: 1px solid rgb(213, 213, 213); */
    border-radius: 0.4rem
}

.asignHisContainer .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgb(233, 233, 233);
    /* padding: 1vmax 3vmax; */
    /* border-radius: 0.4rem; */
    border-radius: 0.4rem !important;
    overflow: hidden;
}

.asignHisContainer .heading>h3 {
    width: 50%;
    height: 100%;
    padding: 0.4vmax 3vmax;
    font: 600 1vmax 'Roboto';
    color: var(--textC1);
    text-align: center;
    

}

.btc1 {
    /* border-bottom: 3px solid rgb(0, 138, 64); */

}

.btc2 {
    /* border-bottom: 3px solid rgb(0, 150, 177); */
    border-left: 1px solid rgb(255, 255, 255);

}

.asignItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background-color: rgb(233, 233, 233); */
    padding: 0.6vmax 3vmax;
    border-radius: 0.4rem;
    border-top: 1px solid rgb(233, 233, 233);
}

.submitItem:first-child {
    border-top: none !important;
}

.asignItem>h3 {
    font: 400 0.9vmax 'Roboto';
    color: var(--textC1);
    width: 50%;
    text-align: center;

}

.noUserAsignBox {
    padding: 2vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 1rem; */
}

.noUserAsignBox>h2 {
    font: 600 1.4vmax 'Roboto';
    color: var(--textC1);
}

.noUserAsignBox>svg {
    font-size: 2vmax;
    color: var(--textC1);
}