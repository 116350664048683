.StudentDetailsBox {
    width: 90%;
    height: 100%;
    /* background-color: aqua; */
    padding: 2vmax;
    margin: 0 auto;
}

.studentUnderBox {
    background-color: white;
    box-shadow: 0 0 10px rgb(163, 214, 251);
    /* padding: 1vmax; */
    /* margin: 0 0; */
    border-radius: 0.4rem;
    overflow: hidden;
    margin-top: 1vmax;
}

.su-topHeader {

    padding: 1vmax 1vmax;
    background: linear-gradient(to right,  var(--bgBlue) , rgb(113, 113, 234));
    margin-bottom: 1.8vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.su-topHeader>h3 {
    color: var(--textC2);
    font: 600 1vmax 'Roboto';
}

.editBtn{
    text-decoration: none;
    font: 600 1vmax 'Roboto';
    border: 2px solid white;
    padding: 0.2vmax 0.5vmax;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border-radius: 0.3rem;

    color: var(--textC1);
    background-color: white;
}

.editBtn:hover {
    transition: all 0.5s;
    background-color: transparent;
    color: white;

}

.st-d-item {
    width: 100%;
    padding: 0.4vmax 2vmax;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.st-d-item>div {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    /* background-color: aqua; */
    border: 0.5px solid rgb(191, 216, 252);
    padding: 0.8vmax;
    border-radius: 0.4rem;
}

.viewBox {
    width: 65%;
    /* border: 1px solid gray; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.st-d-item>div>p:first-child {
    width: 35%;
    font: 500 1vmax 'Roboto';
    color: var(--bgBlue2);
    /* background-color: antiquewhite; */
}

.st-d-item>div>p:last-child {
    width: 65%;
    font: 500 1vmax 'Roboto';
    color: var(--textC1);

}

.st-document-item {
    width: 100%;
    padding: 0.8vmax 2vmax;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: 1rem;
}

.doc-item {
    min-width: 22%;
    border: 0.4px solid rgb(222, 222, 222);
    border-radius: 0.6rem;
    padding: 1vmax;
    /* display: flex;
    justify-content: space-between; */
}

.doc-item>div:first-child {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}
.doc-item>div:last-child {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.doc-item>div>p {
    font: 500 1vmax 'Roboto';
    color: var(--textC1);
}

.doc-download {
    /* width: 1vmax; */
    text-decoration: none;
    /* background-color: var(--bgBlue); */
    background-color: var(--textC2);
    color: var(--textC2);
    font: 500 1vmax 'Roboto';
    padding: 0.2vmax 0.5vmax;
    border: none;
    text-align: center;
    border-radius: .2rem;
    transition: all 0.5s;
    > svg{
        color: var(--bgBlue);
    }
    margin-left: 0.5vmax;
    cursor: pointer;
}

.doc-download:hover {
    /* background-color: var(--bgHov); */
    box-shadow: 0 0 15px var(--bgBlue2);
}

.doc-item>div>img {
    height: 14vh;

}

.driveBox {
    padding: 2vmax;
}

.commentBox {
    margin: 0 2vmax;
    border: 1px solid rgb(191, 216, 252);
    padding: 1vmax;
    border-radius: 0.4rem;

    >p>b {
        color: var(--bgBlue2);
        font: 600 1vmax 'Roboto';
    }

    >p {
        color: var(--textC1);
        font: 500 1vmax 'Roboto';
    }
}

.driveLinkBtn {
    background-color: var(--bgBlue2);
    text-decoration: none;
    color: var(--textC2);
    padding: 0.8vmax 1vmax;
    border-radius: 0.5vmax;
    box-shadow: 0 0 10px gray;
}

.viewBtn {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0.2vmax;
    cursor: pointer;


}

.viewBtn:hover {
    >svg {
        color: var(--bgBlue2);
    }
}

.driveLinkBtn:hover {
    background-color: var(--bgBlue);
}

/* lead-upload-box */
.lead-upload-box {
    padding: 1.2vmax 2vmax;
}

.lead-upload-box>h3 {
    font: 900 1.2vmax 'Roboto';
    color: var(--bgBlue);
}

.lead-upload-box>div {
    display: flex;
    flex-direction: column;
    gap: 0.2vmax;
    font: 500 1.1vmax 'Roboto';
    color: var(--textC1);
}
.btnBox{
    display: flex;
    flex-direction: row !important;
    align-items: center;
}
.assign-topHeader{
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    /* align-items: center; */
    justify-content: space-between;

    >h3{
        width: 50%;
        font: 900 1.2vmax 'Roboto';
        color: var(--bgBlue); 
    }
    
}
.assignBtn{
    background-color: var(--bgBlue);
    color: var(--textC2);
}
.assignBtn:hover{
    color: var(--textC1);
    border: 2px solid var(--bgBlue);
}
.notFoundText{
    width: 100%;
    border: 1px solid var(--textC1);
    border-radius: 0.4rem;
    padding: 1vmax;
    text-align: center;
    font-size: large;
}
.StatusBox{
    padding: 0vmax 2vmax 1vmax 2vmax;
    display: flex;
    justify-content: space-between;
   
}
.whatsappBtn{
    height: 2.6vmax;
    padding: 0vmax 1.5vmax;
    border: none;
    background-color: rgb(0, 219, 0);
    color: var(--textC2);
    border-radius: 2rem;
    font: 600 1vmax 'Roboto';
    display: flex;
    gap: 0.3rem;
    align-items: center;

}
.whatsappBtn:hover{
    background-color: green;
    cursor: pointer;
}
.StatusBox > div{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    padding: 0.5vmax 1vmax ;
    gap: 1rem;
    border: 1px solid rgb(191, 216, 252);
    background-color: rgba(239, 246, 255, 0.905);
    > p{
        font-size: medium;
        padding-bottom: 0.1vmax;
    }
    > h4{
        color: gray;
    }
}
.doc_heading{
    padding: 0 2vmax;
    color: var(--bgBlue);
}

.isDeleteFileBox{
    position: absolute;
    top: 50%;
    left: 40%;
    box-shadow: 0 0 10px rgb(97, 92, 151);
    background-color: white;
    padding: 2vmax;
    width: 30%;
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
    border-radius: 0.4rem;
    
}

.isDeleteFileBox > div:last-child{
    display: flex;
    gap: 1rem;

    >button{
        /* background-color:whi; */
        padding: 0.4vmax 1vmax;
        border-radius: 0.4rem;
        box-shadow: 0 0 6px rgb(91, 132, 168);
        border: none;
        cursor: pointer;


    }
    >button:hover{
        background-color: antiquewhite;
    }
}
.delDel{
    background-color: red;
    color: white;
}
.delDel:hover{
    background-color: rgb(255, 74, 74) !important;
}