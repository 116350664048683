.commentContainer {
    padding: 2vmax;

    border-top: 2px dashed rgb(225, 225, 225);
}

.commentContainer>h3 {
    font: 600 '1vmax' 'Roboto';
    color: var(--textC1);
    padding: 0.5vmax;
}

.addCommentBox {
    display: flex;
    gap: 0.5rem;
}

.inputField {
    padding: 0.5vmax;
    width: 50%;
    border-radius: 0.4rem;
    border: 1px solid gray;
}

.commentsBox {
    padding: 0.7vmax;
}

.commentItem {
    display: flex;
    gap: 0.5vmax;
    margin: 0.2vmax 0;
}

.profileIcon {
    border: 1px solid gray;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(227, 227, 227);
    border-radius: 2rem;

    >svg {
        color: gray;
        font-size: medium;

    }
}

.delCom{
    display: flex;
    align-items: center;
    background-color: rgb(231, 231, 231);
    border: 1px dotted gray;
    font-size: small;
    padding: 0 0.5vmax;
    border-radius: 2rem;
}
.commentField {
    display: flex;
    flex-direction: column;
}

.commentField>p:first-child {
    font: 300 0.8vmax 'Roboto';
    color: var(--textC1);
}

.commentField>p:last-child {
    display: flex;
    gap: 1rem;
    font: 300 1vmax 'Roboto';
    color: var(--textC1);
    align-items: center;
}

.btns {
    display: flex;
    gap: 0.5rem;
}

.editBtn1 {
    background-color: transparent;
    border: none;
    outline: none;
    color: gray;
}

.editBtn1:hover {
    color: var(--bgBlue);
    cursor: pointer;
}

.delBtn {
    background-color: transparent;
    border: none;
    outline: none;
    color: gray;
}

.delBtn:hover {
    color: var(--bgBlue);
    color: red;
    cursor: pointer;
}

.updateBox {
    padding: 0.8vmax;
    display: flex;
    gap: 0.5rem;
}

.saveBtn {
    height: 2.4vmax;
    padding: 0.2vmax 1vmax !important;
}

.canBtn {
    height: 2.4vmax;

    padding: 0.2vmax 1vmax !important;
    background: none !important;
    border: 1px solid var(--bgBlue);
    color: var(--bgBlue);
}
.loadMoreBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    background-color: none;
    border: none;

    padding: 0.2vmax 1vmax;
    color: gray;
    border-radius: 2rem;
    font: 300 0.8vmax 'Roboto';
}

.loadMoreBtn:hover{
    cursor: pointer;
    background-color: var(--bgBlue);
    color: var(--textC2);

}