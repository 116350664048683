.profileBox{
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
    background-color: transparent !important;

    padding: 1vmax !important;
    border-radius: 0 !important;
    height: auto !important;
    text-align: center;
}
.profileBox > img{
    width: 80px;
    height: 80px;
    border: 2px solid var(--bgBlue);
    padding: 0.4vmax;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center !important;
    align-items: center;
}
.fileInp{
    width: 100%;
    /* padding: 0 2vmax; */
    margin-left: 1.1vmax;
    border-radius: 0.4rem;
    border: 1px solid gray;
}
.fileInp::-webkit-file-upload-button{
    padding:  1vmax;
    border: none;
    background-color: var(--bgBlue);
    color: var(--textC2);
}
@media screen and (max-width:600px) {

    .profileBox > img{
    width: 12vmax;
    height: 12vmax;
    border: 1px solid gray;
    pad: 0.4vmax;
    background-color: rgb(237, 237, 237);
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center !important;
    align-items: center;
}
}