/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); */
@font-face {
  font-family:"Roboto" ;
  src: url('./font/Poppins-Regular.ttf');


}
@font-face {
  font-family:"roboto" ;
  src: url('./font/Poppins-Regular.ttf');

} 
@font-face {
  font-family:"FontLogo" ;
  src: url('./font/dance/DancingScript-Bold.ttf');

} 
*{
  max-width: 100%;
  box-sizing: border-box;
  
}
/* 
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=textC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sansita&display=swap'); */
*{
    margin: 0;
    padding: 0;
    max-width: 100%;
    box-sizing: border-box;

    user-select: none; /* Disable text selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  
}
:root{
  --bgBody1: rgb(237, 255, 255) ;
  --bgBody2: rgb(213, 213, 251);
  --bg1: rgb(146, 147, 147);
  --bg2:rgb(234, 232, 232);
/* 
  --bgBlue:#F42022;
  --bgBlue2:rgb(255, 98, 98); */


  --bgBlue:#3c4151;
  --bgBlue2:#3c4151; 
  --bgBlueLight: #4d556d;

  --bgHov:#3c4151;
  --textC1:rgb(82, 82, 82);
  --textC2: white;
  --border:rgb(102, 102, 212);
  --bi1: rgb(239, 239, 239);
  --bi2: rgb(238, 238, 238);
  --hoverText:rgb(37, 98, 232);
  --hoverborder: rgb(37, 98, 232);
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{
  color: white !important;
  background: linear-gradient(to right, var(--bgBlue) ,var(--bgBlueLight)) !important;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
  width: 8px;
  height: 8px;
  background-color: rgba(179, 179, 179, 0.059);

}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb{
  background-color: rgba(221, 221, 221, 0.37);

}

@media print {
  html , body {
    display: none;
  }
}


.MuiDataGrid-columnHeaderTitle{
  color: white !important;
}