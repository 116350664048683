.ButtonLayer{
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    padding: 1vmax;
}
.BlRow{
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    padding: 0.5vmax 2vmax;
    gap: 2rem;
}
.blBox1{
    display: flex;
    gap: 1rem;
}
.blBox2{
    /* background-color: aqua !important; */
    /* height: 100%; */
    display: flex;
    align-items: flex-start;
    /* gap: 1rem; */
}
.blBox1 >div{
    /* background-color: var(--bg1); */
    /* background-image: linear-gradient(to right, var(--bi2), white) !important; */
    /* background-color: var(--bi2) !important; */
    /* background-color: rgb(254, 254, 254) ; */
    
    /* border-bottom:  5px solid var(--border) !important; */
    box-shadow: 0 0 3px gray;

    padding: 1.5vmax 5vmax !important;
    border-radius: 0.4rem;
    /* color: rgb(255, 255, 255) ; */

}
.blBox1 >div > p{
    font: 600 1.4vmax 'Roboto';
    color: var(--textC2) !important;
    text-align: center;
    /* color: rgb(34, 61, 79)/ */
}
.iconsSty{
    font-size: 1.5vmax;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{
    width: 100%;
    color: var(--textC2);
}
.usersContainer{
    width: 100%;
    /* height: 71%; */
    /* overflow: auto; */
    padding: 2vmax;
    /* background-color: aquamarine; */
    font-size: 1.2vmax;
    /* font-family: 'Courier New', Courier, monospace; */
}

.actionIconBox{
    display: flex;
}
.actionIconBox > a{
    /* width: 24px !important; */
    text-decoration: none;
    color: gray;

}
.actionIconBox > a >svg{
    font-size: 24px !important;
    color: gray !important;


}
.usersTable{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.MuiDataGrid-root { font-size: 18px; }
.navigate-btn{
    background-color: var(--border);
    padding: 1vmax;
    text-decoration: none;
    color: var(--textC2);
    font: 300 1.3vmax 'Roboto';
    border-radius: 0.4vmax;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    gap: 0.5vmax;
}
.navigate-btn:hover{
    background-color:  var(--border);
    color: var(--textC1);
}
.modalBox{
    /* width: ; */
    padding: 2vmax;
}
.modalBox > h2{
    /* padding: 1vmax 0; */
    padding-bottom: 2vmax;
    color: rgb(63, 63, 63);
}
.modalBox > div{
    display: flex;
    /* justify-content: ; */
    gap: 1rem;
}
.modalBox > div >button:first-child{
    padding: 0.8vmax 2vmax;
    font: 600 1.2vmax 'Roboto';
    background-color: var(--bgBlue);
    border: none;
    border-radius: 0.4rem;
    color: white;
}
.modalBox > div >button:last-child{
    padding: 0.8vmax 2vmax;
    font: 600 1.2vmax 'Roboto';
    background-color: tomato;
    border: none;
    border-radius: 0.4rem;
    color: white;
}
.verified{
    padding: 0.0vmax 1vmax;
    background-color: rgb(180, 251, 180);
    border-radius: 4rem;
    color: var(--textC1);
}
.unverified{
    padding: 0.0vmax 1vmax;
    background-color: rgb(251, 180, 180);
    border-radius: 4rem;
    color: var(--textC1);
}
.greenColor{
    background-color: rgb(0, 206, 0);
    color: white;
    padding: 0 0.8vmax;
    padding-bottom: 0.18vmax;
    font: 300 0.9vmax 'Roboto';
    border-radius: 2rem;
}
.grayColor{
    border: none;
    background-color: rgb(242, 242, 242);
    color: rgb(149, 149, 149);
    padding: 0.2vmax 0.8vmax;
    padding-bottom: 0.18vmax;
    font: 300 0.9vmax 'Roboto';
    border-radius: 2rem;
    cursor: pointer;
    > svg{
        color: rgb(149, 149, 149);
    }

}
.grayColor:hover{
    background-color: rgb(158, 158, 158);
    color: white;
    > svg{
        color: white;
    }
}
.redColor{
    /* color: ; */
    background-color: rgb(255, 1, 1);
    color: white;
    padding: 0 0.8vmax;
    padding-bottom: 0.18vmax;
    font: 300 0.9vmax 'Roboto';
    border-radius: 2rem;
}