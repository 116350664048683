.StudentUpdateBox{
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
    padding: 2vmax;
    margin-bottom: 2vmax;
}
.assignContainer{
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 0.5vmax;
    padding-bottom: 1.5vmax;
}
.StudentUpdateBox > form{
    background-color: white;
    box-shadow: 0 0 5px var(--bgBlue);
    padding: 2vmax;

    border-radius: 0.4rem;
    overflow: hidden;
}
.StudentUpdateBox > form > h3{
    padding: 1.3vmax 1vmax;
    background:linear-gradient(to right, var(--bgBlue) , var(--bgBlueLight)) ;
    color: var(--textC2);
    font: 600 1.2vmax 'Roboto';
    border-radius: 0.4rem;
    margin-bottom: 1.2vmax;
}

.updateItemBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0.6vmax;
}


.inputItem,
.seletItem,
.updateInputItem{
    padding: 0.2vmax;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    /* background-color: aqua; */


}

.updateInputItem > p,
.seletItem > p,
.inputItem > p{
    font: 600 1vmax 'Roboto';
    color: var(--bgBlue2);
    padding: 0 0.5vmax ;
}
.updateInputItem > select,
.updateInputItem > input,
.seletItem > select,
.inputItem > input{
    width: 100%;
    padding: 0.8vmax;
    font: 300 1vmax 'Roboto';
    border-radius: 0.4rem;
    /* outline: 2px solid var(--bgBlue); */
    outline: none;
    border: 1px solid var(--bgBlue2);
}
.updateInputItem > select:focus,
.updateInputItem > input:focus{
    border: 0px solid var(--bgBlue2);
    box-shadow: 0 0 5px blue;
}

.roleBox{
    display: flex;
    gap: 0.6rem;
}
.roleBtn{
    padding: 0.4vmax 1vmax;
    font: 600 0.8vmax 'Roboto';
    background-color: white;
    outline: none;
    border: none;
    border-radius: 0.2rem;
    color: var(--textC1);
}
.roleBtn:hover{
    background-color: rgb(221, 221, 221);
    cursor: pointer;
}

.activeRole{
    background-color: rgb(146, 208, 255);
}

.activeRole:hover{
    background-color: rgb(146, 208, 255);

}
/* .inputItem > input */
.fileItem{
    gap: 0;
    padding: 0 0.9vmax;
}
.fileBtn{
    padding: 0 !important;
}
.fileBtn::file-selector-button{
    background-color: var(--bgBlue);
    width:15%;
    height: 7vh;
    color: white;
    border: none;
    outline: none;
}
.fileItem > p{
    padding-bottom: 0.4vmax;
}
.ActionBox{
    padding: 0.5vmax 1.5vmax;
    display: flex;
    gap: 1rem;
}
.commentsInput{
    width: 100%;
    min-height: 200px;
    border-radius: 0.4rem;

    padding: 1vmax;
}
.visaInput{
    width: 100%;
    min-height: 100px;
    border-radius: 0.4rem;

    padding: 1vmax;
}
.ActionBox > button{
    padding: 0.6vmax 1.5vmax;
    font: 500 1.2vmax 'Roboto';
    border-radius: 0.4rem;
    outline: none;
    border: none;
    box-shadow: 0 0 5px gray;
    cursor: pointer;
    transition: all 0.5s;
}
.addBtn{
    background-color: rgb(100, 127, 100);
    color: var(--textC2);
}
.addBtn:hover{
    background-color: var(--bgHov);

}
.textArea{
    border-radius: 0.4rem;
    padding: 1vmax;
    font: 400 0.9vmax 'Roboto';
    min-height: 10vmax;
    min-width: 100%;
}
.closeBtn{
    background-color: rgb(250, 140, 120);
    color: var(--textC2);
}
.closeBtn:hover{
    background-color: tomato ;

}
.submitItem{
    padding: 1vmax 1.5vmax;
    padding-bottom: 3.5vmax;

}

.submitItem >input{
    width: 100%;
    padding: 0.6vmax 3vmax;
    font: 300 1.2vmax 'Roboto';
    border-radius: 0.4rem;
    /* outline: 2px solid var(--bgBlue); */
    outline: none;
    border: 2px solid var(--bgBlue);
    background:linear-gradient(to right, var(--bgBlue) , var(--bgBlueLight)) ;

    color: white;
}
.submitItem >input:hover{
    
    background:linear-gradient(to right,  var(--bgBlueLight),var(--bgBlue) ) ;
    
}