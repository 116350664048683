.student-date-box > form{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.docBtn1 {
    border: none;
    height: 45px;
    padding: 0.8vmax 1.5vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, rgb(208, 208, 208) ,rgb(216, 201, 255)) ;
    color: var(--textC2);
    font: 500 1vmax 'Roboto';
    border-radius: 0.4rem;
    text-decoration: none;
    transition: all 0.5s;
    box-shadow: 0px 3px 10px rgb(222, 222, 222);
    color: rgb(61, 61, 61);

}

.docBtn1:hover {
    cursor: pointer;
    background: linear-gradient(to right, rgb(216, 201, 255),  rgb(202, 202, 202)) ;
}
.screenshotItem{
    width: 48%;
    padding: 0.5vmax;

    background-color: rgb(236, 236, 236);
    border-radius: 0.5rem;

}
.screenshotItem1{
    display: flex;
    align-items: center;
    justify-content: space-between;

    >p{
        font-size: 0.9vmax;
        color: var(--textC1);
        padding: 0.2vmax;
    }
}

.imgTag{
    min-height: 10vh;
    font-size: small;
    padding: 0.5vmax;
    color: rgb(124, 124, 124);
}

.screenshotContainer{
    padding: 1vmax;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}