

  .MuiDataGrid-virtualScroller::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    border-radius: 2rem !important;
    background-color: rgba(199, 199, 199, 0.059);

  
  }
  .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb{
    background-color: rgba(200, 200, 200, 0.37);
    border-radius: 2rem !important;
  
  }
/* .fileDownloadBtn{
    background-color: var(--bgBlue);
    text-decoration: none;
    color: var(--textC2);
    padding: 0.6vmax 1vmax;
    border-radius: 0.6rem;
    font: 300 1.1vmax 'Roboto';
    transition: all 0.5s;
}
.fileDownloadBtn:hover{
    background-color: var(--bgHov);

} */
 .dashboard-folderListBox{
    padding: 2vmax;
    

    >h2{
        font: 600 1.2vmax 'Roboto';
        color: gray;
        margin: 1vmax 0;
    }
 }
 .folderAllListBox{
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1vmax;
    /* border: 1px solid gray; */

 }

 .folderItem{
    display: flex;
    padding: 1vmax;
    box-shadow: 0 0 10px rgb(210, 210, 210);
    border-radius: 0.4rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    text-decoration: none;
 }
 .folderItem:hover{
    background-color: rgb(239, 249, 255);
 }
.hide{
    /* position: relative; */
    transition: all 0.4s ease-in-out;
    left: -100%;
    /* display: none; */

}
.menuContainer{
    min-width: 20%;
    /* max-width: 100%; */
    height: 100vh;
    position: relative;
    z-index: 99;
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
}
.show{
    /* position: relative; */
    transition: all 0.4s ease-in-out;
    left: 0;
}

.usersContainer{
    padding: 1vmax 2vmax !important;
}
@media screen and (max-width:800px) {
    .menuContainer{
        width: 70%;
        height: 100vh;
        top: -15px;
        position: absolute;
        z-index: 999;
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }
    .show{
        /* position: relative; */
        transition: all 0.4s ease-in-out;
        left: -15px;
    }
    
  
}