.profileContainer{
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    padding: 1vmax;

}

.profileContainer > div{
    display: flex;
    width: 100vw;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.profileContainer > div:first-child > h1{
    width: 100%;
    margin: 1vmax 0;
    color: black;
    font:600 2.2vmax "Roboto";
    text-align: center;
}

.profileContainer > div:first-child > img { 
    width: 16vmax;
    height: 16vmax;
    border-radius: 100%;
    border: 1px solid gray;
transition: all .5s;
background-image: url("https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-female-5-512.png");
background-position: center;
background-repeat: no-repeat;
background-size: contain;
/* box-shadow:  0 0 10px var(--bgBlue); */
}

.profileContainer > div:first-child > img:hover{
    /* box-shadow: 2px 5px rgb(218, 218, 218) 5px; */
    transform: scale(1.05);
}


.profileContainer > div:first-child > a{
    width: 30%;
    background-color: var(--bg3);
    color: black;
    margin: 4vmax;
    text-align: center;
    padding: 0.5vmax;
    font: 400  1vmax "Roboto";
    transition: all 0.5s;

}



.profileContainer > div:Last-child {
    justify-content: space-evenly;
    padding: 2vmax 5vmax;
    box-sizing: border-box;
    align-items: flex-start;
   background-color: white;
   border-bottom: 4px solid var(--border);

    border-radius: 0.4rem;
    box-shadow: 0 0 10px rgb(240, 240, 240);
}
.profileContainer > div:Last-child > div{
    width: 100%;
    display: flex;
    gap: 0.5rem;
}

.profileContainer > div:Last-child > div >h4{
    width: 24%;
    color: rgba(37, 44, 46, 0.73);
    font:600 1vmax Roboto;
    padding: 0.2vmax;

}

.profileContainer > div:Last-child > div >p{
    width: 60%;
    color: rgb(24, 19, 19);
    font:300 1vmax 'Roboto';
    padding: 0 1vmax;
    text-align: left;
    border-radius: 0.4rem;
}

.profileContainer > div:Last-child > div:last-child{

display: flex;
flex-direction: column;
width: 100%;
gap: 0;
padding-top:2vmax ;
}

.profileContainer > div:Last-child > div:last-child > a{
    width: 65%;
    background-color: var(--border);
    box-shadow: 0 0 4px rgb(213, 213, 213);
    color: white;
    margin: .3vmax 0;
    text-align: center;
    padding: 0.5vmax; 
    font: 400  1vmax "Roboto";
    border-radius: 0.4rem;
    text-decoration: none;
    transition: all 0.5s;
}


.profileContainer > div:Last-child > div:last-child > a:hover{
    background-color: rgb(86, 86, 86);
    color: white;
}

@media screen and (max-width: 600px) {
    .profileContainer{
        flex-direction: column;
    }
    
    .profileContainer > div:first-child{
        justify-content: center;
        align-items: center;
    }
.profileContainer > div:first-child > h1{
 font-size: 3.2vmax;   
}


.profileContainer > div:first-child > img { 
    width: 25vmax;
    height: 25vmax  ;
    border-radius: 100%;
    border: 1px solid gray;
transition: all .5s;
}

.profileContainer > div:first-child > a{
    width: 40%;
    color: black;
    margin: 4vmax;
    text-align: center;
    padding: 0.6vmax;
    font: 400  2vmax "Roboto";
    transition: all 0.5s;
}


.profileContainer > div:Last-child {
    justify-content: space-evenly;
    padding: 3vmax;
    box-sizing: border-box;
    align-items: center;
}

.profileContainer > div:Last-child > div >h4{
    font:400 2.7vmax Roboto;

}
.profileContent{
    display: flex;
    flex-direction: row;
}
.profileContainer > div:Last-child > div{
    text-align: center;
}
.profileContainer > div:Last-child > div >p{
    font:300 0.8vmax 'Roboto';
}
.profileContainer > div:Last-child > div >p{
    font:300 1.3vmax 'Roboto';
}
.profileContainer > div:Last-child > div:last-child{

    display: flex;
    align-items: center;
    width: 100%;
    }
    .profileContainer > div:Last-child > div:last-child > a{
        width: 70%;
        margin: .5vmax 0;
        text-align: center;
        padding: 1vmax;
        font: 400  2vmax "Roboto";
        border-radius: 0.4rem;
        transition: all 0.5s;
    }


}