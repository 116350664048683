.update-by-container{
    padding:  0vmax 2vmax ;
    padding-bottom: 2vmax;
}

.update-by-container > h3{
    font: 600 1vmax 'Roboto';
    padding: 0.3vmax;
    width: 10vmax;
    background-color: var(--bgBlue);
    border-radius: 2rem;
    text-align: center;
    color: var(--textC2);
}

.update-by-container > p{
    font: 600 0.9vmax 'Roboto';
    padding: 0.1vmax 0.99vmax;
    color: var(--textC1);
}