.modal-Container{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999 !important;

    padding: 2vmax 7vmax;
}

.modal-Box{
    width: 100vw;
    height: 90vh;
    background-color: white;
    padding: 1vmax;
    border-radius: 0.8rem;
    box-shadow: 1px 1px 10px rgb(216, 255, 164);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.acceptedBox{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1vmax;
}