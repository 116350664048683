.ButtonLayer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vmax;
}
.blBox1{
    display: flex;
    gap: 1rem;
}
.blBox1 >div{
    background-color: var(--bg2);
    padding: 1.5vmax;
    border-radius: 0.4rem;

}
.blBox1 >div > p{
    font: 600 1.4vmax 'Roboto';
    color: var(--textC2);
    text-align: center;
}
.usersContainer{
    width: 100%;
    padding: 1vmax;

}
/* .inpBtn::placeholder{
    color: white;
} .inpBtn{
    background-color: transparent;
    color: white;
    /* background-color: white; 
    border: 1px solid white  !important;

} */
.center{
    min-height: 100vh !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center !important;
}
.inpBtn{
}
/* .inpBtn , */
.createUserContainer{
    width: 100%;
    /* height: 80%; */
    display: flex;
    flex-direction: column;
    align-items: center ;
    justify-content: center ;
    margin: 1vmax 0;
    /* background-color: aqua; */
}
.inpBtn > option {
    color: black;
    /* padding: 1vmax; */
    /* margin: 1vmax; */
    /* background-image: linear-gradient( rgb(173, 246, 246), rgb(87, 137, 152)); */
    background-color: rgb(255, 255, 255);
}

.createUserContainer > form{
    width: 50%;
    padding: 2vmax 2vmax;
    /* padding-bottom: 4vmax; */
    /* padding-right: 2vmax; */
    border-radius: 0.4rem;
    box-shadow:  0 0 15px rgb(223, 223, 223);
    /* border-bottom: 4px solid var(--border); */
    background-color: white;
    overflow: hidden;
    /* background-image: linear-gradient( rgb(148, 162, 166), rgb(0, 123, 161)) ; */
    /* background-image: url('https://cdn.wallpapersafari.com/94/41/0JTynN.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */

}
.createUserContainer > form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.createUserContainer > form > h3{
    /* padding: 1vmax 0; */
    padding: 1vmax;
    /* border-bottom: 1px solid gray; */
    color: var(--textC2);
    text-align: center;
    background: linear-gradient(to right, var(--bgBlue) , var(--bgBlueLight));
    border-radius: 0.4rem;
    margin-bottom: 2vmax;
    display: flex;
    align-items: center;
    justify-content: center;
}
.createUserContainer > form > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.4vmax 0 0;

    /* gap: 1rem; */
     /* border: 1px solid gray */
}
.createUserContainer > form > div > svg{
    position: relative;
    left: 40px;
    font-size: 22px;
    color: var(--bgBlue);
}
/* .submintBtn:hover{
    cursor: pointer;
    transition: all 0.5s;
    color: white;
    background-color: ;

} */

@media screen and (max-width:600px){

    .createUserContainer > form{
    width: 100%;
    padding: 2vmax 1vmax;
    /* background-color: aquamarine; */
}
}