.login-loading{
    width: 100vw;
    height: 100vh;
    display: flex;
    /* flex-direction: column; */
    justify-content: center ;
    align-items: center;
    max-width: 100%;
    background-color: white;
}

.login-loading > div {
    width: 4vmax;
    height: 4vmax;

    /* background-color: tomato; */
    border-bottom: 3px solid var(--bgBlue);
    border-radius: 50%;
    animation: login-loadingRotate 800ms linear infinite ;

}
.login-loading > img{
    margin: 0 auto;

    /* > img{ */
        mix-blend-mode: darken;
    /* } */
}

@keyframes login-loadingRotate {
    to{
        transform: rotateZ(-360deg);
    }
}