

/* --------------------------------------- */
.loginContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 1vmax;
    background-color: rgba(255, 255, 255, 0.858);
    
    
}
.loginContainer > div{
    width: 50%;
}
.imgBox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* background-color: aqua; */
    padding: 4vmax;
    /* padding-left: 4vmax; */
}
.formBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 90%;
    /* box-shadow: 0 0 5px gray; */
    border-radius: 0.4rem;
    padding: 3vmax 4vmax;
    text-align: center;
    /* background-color: rgba(223, 223, 255, 0.588); */

}
.formBox > h2{
    /* text-align: left; */
    width: 100%;
    font: 600 1.8vmax 'Roboto';
    padding: 2vmax 1vmax;
    text-align: center;
    color: var(--bgBlue);
}

.formBox > form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 0vmax;

    
}
.formBox > form > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid gray; */
}
.formBox > form > div > svg:first-child{
    position: relative;
    left: 40px;
    font-size: large;
    color: var(--bgBlue);

}
.formBox > form > div > svg:last-child{
    position: relative;
    /* left: -40px; */
    /* right: 0; */
    padding-left: 10px;
    font-size: large;

}
.logInpBtn{
    width: 100%;
    padding: 0.8vmax 3vmax;
    border-radius: 0.4rem;
    font: 400 1.2vmax 'Roboto';
    border: 1px solid var(--bgBlue) ;
    outline: none;
    background-color: white !important;

}
.inpBtn{
    width: 100%;
    padding: 0.8vmax 3vmax;
    border-radius: 0.4rem;
    font: 400 1.1vmax 'Roboto';
    border: 1px solid var(--bgBlue) !important ;
    /* outline: none; */
    color: gray;

}
.inpSelBtn{
    width: 100%;
    padding: 0.8vmax 3vmax;
    border-radius: 0.4rem;
    font: 400 1.1vmax 'Roboto';
    border: 1px solid var(--bgBlue) !important ;
    /* outline: none; */
    color: gray;
}
.submintBtn{
    width: 100%;
    padding: 0.5vmax 2vmax;
    border-radius: 0.4rem;
    font: 400 1.2vmax 'Roboto';
    border: 1px solid gray;
    outline: none;
    color: white;
    background-color:var(--bgBlue);
    text-align: center;
    margin-left: 20px;
    transition: all 0.5s;
    cursor: pointer;
    transition: all 0.5s;
    border: none;
}
.submintBtn:hover{
    background-color: var(--bgHov);
}
.isTypeBox{
    background-color: antiquewhite;
    padding-left: 1vmax;
}
.regBox > button{
    border: none;
    background-color: transparent;
    font: 600 1vmax 'Roboto';
}
.regBox > button:hover{
    color: var(--bgHov);
    cursor: pointer;
}
.regBox{
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    padding: 1vmax 1.4vmax;
    gap: 0.5rem;
}

.regBox > a,
.regBox > p{
    font: 600 1.1vmax 'roboto';
    text-decoration: none;
    color: var(--textC1);
}
.regBox > a:hover{
    color: var(--bgHove)
}
@media screen and (max-width:600px){
    .loginContainer{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 4vmax 2vmax;
        margin-bottom: 4vmax;
    }
    .loginContainer > h2{

    }
    .loginContainer > div{
        width: 100%;
    }
    .imgBox{
        height: 42%;
        padding: 2vmax;
        padding-left: 4vmax;
        text-align: center;
    }
    .imgBox >img{
        height: 100%;
    }
    .formBox {
        width: 100%;
        height: auto;
        /* box-shadow: 0 0 5px gray; */
        border-radius: 0.4rem;
        padding: 2vmax 1vmax;
    
    }
    .formBox > form > div > svg{
    left: 30px;

}
.formBox > h2{
    /* text-align: left; */
    font: 600 4vmax 'Roboto';
    padding: 2.5vmax 1vmax;
}
.inpBtn{
    width: 100%;
    padding: 2vmax 5vmax;
    font: 400 2.2vmax 'Roboto';
    border: 1px solid rgb(179, 179, 179) !important;
    /* background-color: transparent !important; */
    /* background-color: aqua; */
  

}
.logInpBtn{
    width: 100%;
    padding: 2vmax 5vmax;
    font: 400 2.2vmax 'Roboto';
    border: 1px solid rgb(145, 145, 145);

}
.submintBtn{
    padding: 1vmax 3vmax;
    font: 400 2vmax 'Roboto';
    
}
.regBox > a,
.regBox > p{
    font: 600 2vmax 'roboto';
}
}