.doc-upload-container {
    padding: 1vmax;
}

.doc-upload-container>h3 {
    font: 800 1.4vmax 'Roboto';
    padding-bottom: 0.5vmax;
    color: var(--bgBlue);
}

.d-u-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameInp {
    width: 44%;
    padding: 0.8vmax;
    font: 300 1vmax 'Roboto';
    border-radius: 0.4rem;
    /* outline: 2px solid var(--bgBlue); */
    outline: none;
    border: 1px solid var(--bgBlue2);
}


.cancelBtn {
    cursor: pointer;
    font: 600 1.05vmax 'Roboto';
    padding: 0.4vmax 1.5vmax;
    background-color: var(--bgBlue);
    outline: none;
    border: none;
    border-radius: 2rem;
    color: white;
}

.cancelBtn:hover {
    background-color: var(--bgBlue2);
}

.addBtn {
    cursor: pointer;
    font: 600 1.05vmax 'Roboto';
    padding: 0.6vmax 2vmax;
    background-color: green;
    outline: none;
    border: none;
    border-radius: 2rem;
}

.nameInpFile {
    cursor: pointer;
    border: 1px solid var(--bgBlue);
    padding: 0.5vmax;
    width: 44%;
    border-radius: 0.4rem;
}

.nameInpFile::-webkit-file-upload-button {
    background-color: var(--bgBlue);
    color: var(--textC2);
    padding: 0.5vmax 1vmax;
    border-radius: 0.4rem;
    border: none;
    cursor: pointer;

}

/* .nameInpFile:hover { */
    .nameInpFile::-webkit-file-upload-button:hover {
        background-color: var(--bgHov);
    }
/* } */

.warn{
    font: 300 1vmax 'Roboto';
    color: var(--textC1);
    padding: 0.1vmax 1.4vmax;
}