.MuiDataGrid-columnHeaders {
    /* width: auto !important; */
    background-color: var(--bgBlue) !important;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.451) !important;
}

.dashboardMain {
    width: 100%;
    /* height: 100vh; */
    overflow: auto;
    display: flex;
    /* position: fixed; */
    background-color: white;
    /* background-image: url('../../images/dashbboard2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    /* color: #ff8f8f; */


}

body::-webkit-scrollbar {
    width: 5px;
    background-color: white;
}

body::-webkit-scrollbar-thumb {
    width: 5px;
    background: linear-gradient(var(--bgBlue), var(--bgBlueLight));
}

.dashboardContainer {
    /* position: absolute; */
    min-height: 100vh;
    margin-left: 20%;
    width: 80%;
    /* min-height: 100%; */
    /* overflow: auto !important;  */
    background-color: white;

}

.fileDownloadBtn {
    text-decoration: none;
    padding: 0.5vmax;
    transition: all 0.5s;
    border-radius: 0.3rem;

}

.fileDownloadBtn>svg {
    /* background-color: var(--bgHov); */
    color: var(--bgBlue2);
    font-size: 1.4vmax;

}

.fileDownloadBtn:hover {
    border: 1px solid var(--bgBlue2);
}

.hide {
    /* position: relative; */
    transition: all 0.4s ease-in-out;
    left: -100%;
    /* display: none; */

}

.menuContainer {
    width: 20%;
    /* max-width: 100%; */
    height: 100vh;
    position: fixed;
    /* z-index: 999; */
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: linear-gradient(var(--bgBlue), var(--bgBlueLight));
}

.menuContainer::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
}

.menuContainer::-webkit-scrollbar-thumb {
    background-color: var(--bgBlue);

}

.show {
    /* position: relative; */
    transition: all 0.4s ease-in-out;
    left: 0;
}

.D-ButtonLayer {
    width: 100%;
    /* padding: 1vmax; */
    display: flex;
    justify-content: space-between;
    margin: 0.5vmax 0;
}

.BlRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.5vmax 2vmax;
    padding-top: 1vmax;
    /* gap: 2rem; */
}

.BlRow1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.5vmax 2vmax;
    padding-top: 1vmax;
}

.student-search-box {
    width: 40%;
    /* background-color: gray; */
}

.student-search-box>form {
    width: 100%;
    background: linear-gradient(to right, rgba(102, 124, 237, 0.249), rgba(156, 167, 224, 0.244));
    border-radius: 2rem;
    overflow: hidden;
}

.student-search-box>form:focus {

    border: 2px solid var(--bgBlue) !important;
    /* outline: 2px solid #1bcf85; */
}

.student-search-box>form>input:first-child {
    width: 92%;
    padding: 0.6vmax 1.4vmax;
    /* background-color: transparent; */
    font: 400 1vmax 'Roboto';
    border: none;
    background-color: transparent;
    outline: none;
}

.submitBtn {
    width: 5%;
    padding: 0.25vmax;
    font: 400 1.2vmax 'roboto';
    border: none;
    outline: none;
    border-radius: 2rem;
    background-color: transparent;
    /* border: 1px solid var(--bgBlue); */
    color: var(--bgBlue);
    cursor: pointer;
    transition: all 0.5s;
}

.student-search-box>form>input:last-child:hover {
    background-color: var(--bgBlue2);
    color: white;
}

.BlItem {
    height: 140px;
    width: 22%;
    padding: 1vmax 2vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 0.5rem; */
    background-color: white;
    text-align: left;
    box-shadow: 0 3px 10px rgb(206, 206, 206);
    border-radius: 0.4rem;
    font: 600 1.3vmax 'roboto';
    color: var(--textC1);
}

.BlItem>div>p {
    color: white;
}

.student-icons {
    padding: 0 1vmax;
    cursor: pointer;
}

.sendMailBtn {
    background-color: transparent;
    border: none;
    background-color: var(--bgBlue);
    color: white;
    padding: 0.2vmax 0.5vmax;
    border-radius: 2rem;
}

.sendMailBtn:hover {
    background-color: rgb(233, 233, 233);
    color: rgb(77, 77, 77);
}

.iconBtn {
    background-color: transparent;
    border: none;
}

.student-icons>svg {
    color: var(--bgBlue2);
    font-size: 17px;

}

.student-icons>svg:hover {
    transform: scale(1.2);


    /*     
    display: flex;
    align-items: center;
    justify-content: center; */
}

.BlItem>div>p:first-child {
    font-size: 1vmax;
}

.BlItem>div:last-child {
    width: 45px;
    height: 45px;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 1px solid rgb(255, 255, 255);
    /* background-color: aqua; */
}

.BlItem>div>p:last-child {
    font-weight: 600;
    font-size: 2.2vmax;
    color: white;
}

.BlItem>div>svg {
    font-size: 25px;
}

.BlItem1 {
    background: linear-gradient(to right, #4e36e2, #7463e3);
}

.BlItem2 {
    background: linear-gradient(to right, #48a9f8, #8ac4f3);



}

.BlItem3 {
    background: linear-gradient(to right, #1bcf85, #60e8af);
}

.BlItem4 {
    background: linear-gradient(to right, #8bc741, #b9ee78);


}

.usersContainer {
    padding: 1vmax 2vmax !important;
}

.MuiDataGrid-footerContainer {
    display: none !important;
}

.paginationContainer,
.paginationBox {
    width: 100%;
    padding: 0 2vmax;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    padding-bottom: 2vmax;

}

.pagination {
    /* width: 100%; */
    display: flex;
    /* gap: 1rem; */


}

.pagination>li:first-child {
    border-radius: 0.4rem 0 0 0.4rem !important;
}

.pagination>li:last-child {
    padding: 0 0.5vmax;

    border-radius: 0 0.4rem 0.4rem 0 !important;
}

.pagination>li {
    width: 40px;
    height: 40px;
    list-style: none;
    padding: 0.4vmax 1vmax;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.1vmax;
    /* border: 1px solid var(--bgHov); */
    /* background-color: var(--bgBlue); */
}

.pagination>li>a {
    text-decoration: none;
    color: var(--textC3);
    cursor: pointer;
}

.pagination>li:hover {
    background-color: var(--bgBlue);
    color: var(--textC2);

}

.pageItemActive {
    background-color: var(--bgBlue) !important;
    color: rgb(255, 255, 255) !important;
}

.newApp {
    background-color: rgb(106, 65, 230);
    color: white;
    font-size: small;
    padding: 0.1vmax 1vmax;
    border-radius: 2rem;
    font-size: 0.7vmax;
}
.processingApp{
    background-color: rgb(255, 187, 0);
    padding: 0.1vmax 1vmax;
    border-radius: 2rem;
    color: white;
    font-size: 0.7vmax;
}

.underReviewApp {
    background-color: rgb(255, 222, 37);
    color: white;
    font-size: small;
    padding: 0.1vmax 1vmax;
    border-radius: 2rem;
    font-size: 0.7vmax;

}

.approvedApp {
    background-color: rgb(105, 232, 80);
    color: white;
    font-size: small;
    padding: 0.1vmax 1vmax;
    border-radius: 2rem;
    font-size: 0.7vmax;

}

.rejectApp {
    background-color: rgb(255, 0, 0);
    color: white;
    font-size: small;
    padding: 0.1vmax 1vmax;
    border-radius: 2rem;
    font-size: 0.7vmax;

}

.unknown {
    background-color: rgb(210, 210, 210);
    color: white;
    font-size: small;
    padding: 0.1vmax 1vmax;
    border-radius: 2rem;
    font-size: 0.7vmax;

}

.appButton {
    position: absolute;
    right: 0;
    top: 11%;
    padding: 0vmax 2vmax;
    padding-top: 1vmax;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.appButton>a {
    width: 9vmax;
    padding: 0.4vmax;
}

.appUserBox {
    padding: 0vmax .2vmax;
    padding-bottom: 0.5vmax;

    >h3 {
        font-family: 'Roboto';
        color: var(--textC1);
    }

    >h4 {
        width: 35%;
        font-family: 'Roboto';
        color: var(--textC1);
        padding-bottom: 0.4vmax;
        border-bottom: 1px solid rgb(225, 225, 225);
    }
}

@media screen and (max-width:800px) {
    .menuContainer {
        width: 70%;
        height: 110vh;
        /* top: -15px; */
        /* position: absolute; */
        z-index: 999;
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }

    .show {
        /* position: relative; */
        transition: all 0.4s ease-in-out;
        left: -15px;
    }

    .dashboardContainer {
        width: 100%;
        margin-left: 0;
    }

}