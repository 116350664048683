.monitorUserContainer{
    padding: 2vmax;
}

.monitorUserContainer > div{
    border: 1px solid var(--bgBlue);
    padding: 2vmax;
    border-radius: 0.4rem;
    background-color: var(--bgBlueLight);
    

}

.textWhite{
    color: white !important;
}