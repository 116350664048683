.sideBarContainer {
    height: 100vh;
    width: 100%;
    /* background-image: url('../../images//slidebar.jpg'); */
    /* background-color: #21252a; */

}
.side-des-box{
    /* border: 2px solid red; */
    margin: 0;
    padding: 0;
    min-height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    padding-bottom: 1vmax;
}

.sidebar {
    height: 100%;
    display: flex;
    /* top: 0vmax; */
    flex-direction: column;
    /* background: linear-gradient(var(--bgBlue), var(--bgBlueLight)); */

    /* overflow: auto; */

    /* background-image: linear-gradient(rgb(238, 161, 161), rgb(234, 115, 115)); */
    /* background-color: white; */
    /* background-color: #21252a; */
    /* background-color: rgb(255, 214, 214); */
    /* background: linear-gradient(var(--bgBlue),rgb(255, 194, 194)); */

    /* border-right: 1px solid rgb(206, 206, 206); */

    padding: 0rem 0vmax;
    padding-left: 1vmax;
}

.BrandLogo {
    /* width: 100%; */
    text-align: center;
    padding: 0 0 !important;
    margin: 1vmax 0;
    /* background-color: white !important; */
    border-radius: 0.4rem;

    >h1 {
        padding-left: 0.5vmax;
        letter-spacing: 5px;
        text-align: left;
        font: 900 2.2vmax 'FontLogo';
        color: rgb(255, 255, 255);

    }
}


.sidebar>a>img {
    padding-top: 0.4vmax;
    width: 12vmax;
    transition: all .5s;
    mix-blend-mode: darken;
}

.sidebar>a>img:hover {
    /* filter: drop-shadow(0 0 10px gray); */
    padding-left: none !important;

}

.sidebar a {
    width: 100%;
    text-decoration: none;
    transition: all 0.5s;
    color: var(--textC2);
    padding: 0.7rem 1vmax;
    margin: 0.1rem 0;
    /* background-color: var(--bg1); */
    /* border-radius: 0.4rem; */
}

.sidebar>h4 {
    padding: 0vmax 1vmax;
    margin-top: 0.8vmax;
    background-color: var(--);
    /* color: var(--textC2); */
    color: rgb(255, 220, 220);
    font: 600 0.9vmax "Roboto";
    text-transform: uppercase;
    /* letter-spacing: 1.5px; */
}


.sidebar a:hover {
    color: rgb(109, 109, 109);
    background-color: rgb(255, 255, 255);
    border-radius: 2rem 0 0 2rem;

    /* padding-left: 3vmax ; */
}

.sidebar>a>p {
    display: flex;
    align-items: center;
    font-size: .98vmax;
    font-weight: 600;
    /* color: rgb(255, 204, 0); */

}

.active {
    background-color: rgb(255, 255, 255);
    /* border-radius: 0.4rem; */
    border-radius: 2rem 0 0 2rem;
    color: var(--textC1) !important;
}


.sidebar>a>p>svg {
    margin-right: 0.5rem;
    font-size: medium;
}

.MuiTyopgraphy-root {
    background-color: white !important;
}

.designAvenueAD {
    margin-top: 2vmax;
    padding: 0 1vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: 600 0.72vmax 'Roboto';
    gap: 0.5rem;

    color: rgb(175, 175, 175);

    /* background: transparent; */
    line-height: 2px;


}

.designAvenueAD>a {
    text-decoration: none;

    font: 600 0.77vmax 'Roboto';
    text-align: center;
    color: rgb(249, 249, 249);
}

.designAvenueAD>p>b {}

.daLink:hover{
    background-color: transparent !important;
    color: rgb(202, 145, 255) !important;
}


@media screen and (max-width:600px) {
    .sidebar>a>p {
        display: flex;
        align-items: center;
        font-size: 2vmax;
        font-weight: 600;
        /* color: rgb(255, 204, 0); */

    }

    .sidebar>a>p>svg {
        margin-right: 0.5rem;
    }

}