.inpBorder{
    border: 2px dashed var(--bgBlue2);
    margin: 0 1vmax;
    border-radius: 1rem;
    padding: 3vmax 8vmax !important;
    background-color: aliceblue;

}
.intFileBtn{
    border: 1px solid var(--bgBlue);
    /* border: none; */
    width: 100%;
    padding:  0vmax;
    padding: 0.3vmax !important;
    display: flex;
    flex-direction: column;
    border-radius: 0.4rem;
    background-color: rgb(214, 245, 254);

}
.intFileBtn:hover{
    transform: scale(1.1);
    transition: all 0.8s;
}
.label{
    padding: 0 1.1vmax;
    font-size: medium;
    color: var(--textC1);

}
.fileName{
    margin: 0 auto;
    font-size: medium;
    color: var(--bgBlue2);
}

.intFileBtn::file-selector-button{
    width: 100%;
    height: 7vh;
    /* text-align: center; */
    border-radius: 0.2rem;
    border: none;
    background-color: rgb(103, 138, 168);

    color: rgb(255, 255, 255);
    font-size: large;


}
/* .createUserContainer >form > div {
    background-color: var(--border);
    padding-left: 0vmax;
} */
.template{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1vmax;
}
.template > a{
    text-decoration: none;
    color: var(--textC2);
    background-color: var(--bgBlue);
    padding: 1vmax;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.3rem;
    box-shadow: 0 1px 6px var(--textC1);
}
.template > a:hover{
    transition: all 0.5s;
    background-color: var(--bgHov);
    box-shadow: 0 0px 2px var(--textC1);
}
.submintUploadBtn{
    width: 100% ;
    border: none;
    outline: none;
    padding: 0.5vmax;
    font: 300 1.3vmax 'Roboto';
    color: var(--textC2);
    border-radius: 0.4rem;
    background-color: var(--bgBlue);
    transition: all 0.5s;
    cursor: pointer;

}
.submintUploadBtn:hover{
    background: linear-gradient(to right, var(--bgBlue) , var(--bgBlueLight));
}