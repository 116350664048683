.HeaderContainer {
    position: relative;
    width: 100%;

    /* background-color: white !important; */
    background-color: transparent !important;
    /* background-image:linear-gradient(to right, var(--bi1), var(--bi2) ) !important; */
    /* box-shadow:  0 0 3px ; */
    /* display: flex; */
}

.headerHeading {
    /* height: 0vh; */

    padding: 0.5vmax 2vmax;
    font: 600 1.1vmax 'Roboto';
    color: var(--textC1);
}

.HeaderBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vmax 2vmax;
    background-color: rgba(211, 211, 211, 0.134);


}

.userName {
    font: 300 0.85vmax 'Roboto';
    text-align: center;
    line-height: 18px;

    >b {
        font-size: 1vmax;
    }

}

.HeaderBox>div>h1 {
    color: var(--bgBlue);
    font: 600 1.1vmax 'roboto';
}


.HeaderBox>div {
    /* background-color: black; */
    /* height: 10vh; */
    display: flex;
    gap: 0.5rem;


}

.HeaderBox>div:first-child {
    align-items: center;
}

.HeaderBox>div:last-child {
    display: flex;
    gap: 1vmax;
}

.downNavHide {
    display: none !important;
}

.downNavMenu {
    width: 200px;
    /* minheight: 150px; */
    top: 58px;
    right: 5px;
    position: absolute;
    z-index: 66;
    background-color: white !important;
    box-shadow: 0 0 10px rgb(221, 221, 221);
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1vmax;

    /* border: 1px solid rgb(219, 219, 219); */
}

.downNavMenu>a {
    width: 100%;
    padding: 0.6vmax;
    text-align: center;
    text-decoration: none;
    background-color: rgb(240, 240, 240);
    color: rgb(105, 105, 105);
    font-family: 'Roboto';
    font-size: 1vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vmax;
    border-radius: 0.4rem;
}

.downNavMenu>a:hover {
    /* padding-left: 1vmax; */
    background-color: var(--bgBlue) !important;
    color: white;
}

.profile-Box {
    background-color: white;
    width: 2.7vmax;
    height: 2.7vmax;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.profile-Box>svg {
    font-size: 1.2rem;
    color: gray;
}

.profile-Box:hover {
    background-color: rgb(236, 236, 236);
}

.menu svg {
    /* margin: auto 0; */
    position: relative;
    font-size: 3vmax;
}

.menu {
    display: none !important;
}

.docBtn {
    border: none;
    height: 45px;
    padding: 0.8vmax 1.5vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, var(--bgHov), var(--bgBlueLight));
    color: var(--textC2);
    font: 500 1vmax 'Roboto';
    border-radius: 0.4rem;
    text-decoration: none;
    transition: all 0.5s;
    box-shadow: 0px 3px 10px rgb(222, 222, 222);

}

.longBtn {
    width: 110% !important;
    font-size: small;
    height: 30px;
    padding: 0.4vmax 1vmax !important;

}

.docUserBtn:hover,
.loadBtn:hover,
.docBtn:hover {
    cursor: pointer;
    background: linear-gradient(to right, var(--bgBlueLight), var(--bgHov));
}

.loadBtn {
    border: none;
    background: linear-gradient(to right, var(--bgHov), var(--bgBlueLight));
    padding: 0 0.6rem;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    >svg {
        font-size: 1.5rem;
        color: white;
    }


}

.docUserBtn {
    padding: 0.1vmax 1.2vmax !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bgBlue);
    color: var(--textC2);
    font: 400 1.1vmax 'Roboto' !important;
    border-radius: 0.4rem;
    text-decoration: none;
    box-shadow: 0 0 5px rgb(220, 220, 220);
}

.profile-Box {
    /* background-image: url('https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.notificationBox {
    width: 3vmax;
    display: flex;
    cursor: pointer;


}

.n_num {
    position: absolute;
    padding: 0px 21px;
    font-size: small;
    /* background-color: white; */
    color: red;
    font-weight: 600;
}

.notificationBtn {
    border: none;
    background-color: transparent;
    outline: none;


    >svg {
        font-size: 1.5vmax;
        color: var(--textC1);
    }
}

.notificationBtn>svg:hover {
    color: rgb(48, 106, 156);
}

.emptyNotification {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-align: center;
    padding: 1vmax;
    background-color: rgb(243, 243, 243);
    border-radius: 0.4rem;
}

.NotificationContainer {
    width: 24vmax;
    max-height: 88vh;
    border-radius: 0.4rem;
    padding: 0.8vmax;
    position: absolute;
    top: 4.3vmax;
    right: 1vmax;
    background-color: white;
    box-shadow: 0 0 10px rgb(205, 205, 205);
    z-index: 5;

    display: flex;
    flex-direction: column;

    overflow: auto;

}

.notificationMainBox {
    padding: 2vmax 10vmax;
}

.NotificationContainer::-webkit-scrollbar {
    height: 5px;
    width: 6px;
    /* background-color: gray; */
}

.NotificationContainer::-webkit-scrollbar-thumb {
    height: 5px;
    width: 8px;
    border-radius: 1rem;
    background-color: rgb(195, 195, 195);
}

.notificationHeading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5vmax;

    >a {
        text-decoration: none;
        font-size: small;
        font-weight: 600;
    }

    >p {
        font: 600 0.95vmax 'Roboto';
        color: var(--textC1);
    }
}

.notificationItem {
    display: block;
    text-decoration: none;
}

.notificationItem {
    border: 1px solid rgb(220, 220, 220);
    padding: 0.3vmax 0.5vmax;
    margin-top: 4px;
    border-radius: 0.4rem;
}

.notificationItem:hover {
    /* background-color: rgb(247, 253, 254); */
    box-shadow: 0 0 8px rgb(230, 230, 230);
    transition: all 0.5s;

    >h4 {
        transition: all 0.5s;
        color: rgb(0, 81, 202);
        padding-left: 1vmax;
    }
}

.notificationItem>p {
    font: 600 0.72vmax 'Roboto';
    color: var(--textC1);
    display: flex;
    align-items: center;
    gap: 0.3rem;

    >svg {
        color: var(--textC1);
    }
}

.notSeen {
    background-color: rgb(224, 232, 244);

}

.seen {
    background-color: rgb(255, 255, 255);

    >h4 {
        transition: all 0.5s;
        color: rgb(135, 176, 237) !important;
        padding-left: 1vmax;
    }
    > p {
        font: 600 0.72vmax 'Roboto';
        color: rgb(169, 169, 169);
        display: flex;
        align-items: center;
        gap: 0.3rem;

        >svg {
            color: rgb(169, 169, 169);
        }
    }
}

.date {
    font-weight: 500 !important;
    color: rgb(157, 157, 157) !important;
}

.notificationItem>h4 {
    font: 600 0.8vmax 'Roboto';
    color: rgb(7, 132, 227);
    padding-left: 0.7vmax;

}

.newTag {
    color: red;
    font: 600 0.6vmax 'Roboto';
}

@media screen and (max-width:600px) {
    .menu {
        display: block !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profileBox {
        width: 5.4vmax;
        height: 5.4vmax;
    }

    .profileBox>svg {
        color: rgb(244, 221, 221);
        font-size: 1.5rem
    }
}