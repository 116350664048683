.deviceItem {
    border: 1px solid gray;
    border-radius: 1rem;
    padding: 1vmax;
}

.selectBtn {

    background: linear-gradient(to right, var(--bgBlue), var(--bgBlueLight));
    color: white;
    font-size: medium;
    padding: 0.4vmax 1vmax;
    
}

.selectBtn:hover{
    cursor: pointer;
    background: linear-gradient(to right, var(--bgBlueLight), var(--bgBlue));

}