
.fullWidth{
    width: 100% !important;
    padding: 0 5vmax;
}
.fullWidth > form{
    width: 100% !important;
}
@media screen and (max-width:600px) {
    .createUserContainer {
        padding: 1vmax;
    }
    .createUserContainer > form{
        padding: 8vmax 0;
        padding-right: 2vmax;
    }
}