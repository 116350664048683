.folderContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1.5vmax !important;
}
.folderContainer >h1{
    width: 100%;
    font: 600 1.2vmax 'Roboto';
    color: gray;
    padding: 0.5vmax;
}
.addFolder{
    width: 100%;
    /* padding: 2vmax; */
}
.addFolder > input{
    width: 43.5%;
    padding: 8px;
    margin: 5px;
    border: 1px solid var(--textC1);
    font: 500 1.1vmax 'Roboto';
    border-radius: 0.4rem;
}
.folderListBox{
    padding: 1vmax 2vmax;
    width: 100%;
}
.folderAddBtn{
    padding: 10px 1vmax;
    border-radius: .4rem;
    background-color: var(--textC1);
    color: white;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: medium;
    box-shadow: 0 0 5px rgb(223, 223, 223);
}
.folderAddBtn:hover{
    /* box-shadow: 0 0 10px rgb(129, 135, 255); */
    background-color: rgb(134, 134, 134);
    transition: all 0.5s;
}

.folderDelBtn{
    padding: 3px 0.6vmax;
    background-color: rgb(255, 38, 0);
    color: white;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: 0 0 5px gray;
    border-radius: 0.2rem;
    margin: 0 15px;
}