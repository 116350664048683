.noDataContainer{
    width: 100%;
    min-height: 80%;
    /* background-color: rgb(225, 225, 225) ; */
    border-radius: 0.4rem;

}
.noDataContainer > div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}
.noDataContainer > div >h2{
    font: 600 2vmax 'Roboto';
}
.noDataContainer > div >svg{
    /* font: 600 2vmax 'Roboto'; */
    font-size: 4vmax;
    color: var(--bgBlue);
}
.noDataContainer > div > img{
    width: 40%;
    mix-blend-mode: darken;
}