.reportBox{
    width: 100%;
    padding:  1vmax 2vmax;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

.userBulkReportBtn,
.userReportBtn{
    padding: 0.4vmax 1vmax;
    color: rgb(54, 54, 54);
    border-radius: 0.4rem;
    border: none;
    box-shadow: 0 0 8px gray;
    border: 2px solid blue;
}
.userBulkReportBtn:hover,
.userReportBtn:hover{
    cursor: pointer;
    background-color: blue;
    color: white;

}
.userReportDownloadBtn,
.userDownloadBtn{
    padding: 0.4vmax 1vmax;
    background-color: rgb(0, 116, 151);
    color: white;
    border-radius: 0.4rem;
    border: none;
    box-shadow: 0 0 8px gray;
}

.userReportDownloadBtn:hover,
.userDownloadBtn:hover{
    cursor: pointer;
    background-color: blue;
    color: white;
}

.activeBorder{
 background-color:  blue;
 color: white;
}