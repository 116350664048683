.reportContainer{
    padding:  2vmax;
}
.downloadBtn{
    text-decoration: none;
    background-color: white;
    border: none;

    > svg{
        color: var(--bgBlue);
        font-size: large;
    }
}
.downloadBtn > svg:hover{
    color: rgb(0, 158, 158);
    /* background-color: ; */
    cursor: pointer;
}
.reportBox{
    background-color: rgb(245, 245, 245);
    padding: 1vmax;
    border-radius: 0.4rem;
}
.report-headingBox{
    background-color: white;
    padding: 0.5vmax;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.report-headingBox > h2{
    font: 600 1.2vmax 'Roboto';
    color: var(--bgBlue);
}
.numBox{

    padding: 2vmax;
    padding-top: 0.5vmax;
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.date-box{
    padding: 0 2vmax;
    padding-top: 0.5vmax;
    padding-bottom: 0.5vmax;
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    font: 600 1vmax 'Roboto';
    color: var(--textC1);

    /* gap: 0.5rem; */
}
.reportHeading{
    padding: 0vmax 2vmax;
    padding-top: 1vmax;
    font: 600 1.05vmax 'Roboto';
    
}
.userBox{
    padding: 1vmax 2vmax;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;

    /* justify-content: center; */

}
.userBox > h4{
    font: 600 1vmax 'Roboto';
    color: var(--textC1);
}
.numBox > h4{
    width: 48%;
    /* padding: 1vmax; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font: 600 1vmax 'Roboto';
    color: var(--textC1);
}